"use strict";

import { add } from "lodash";

var SEPARATER = '/';
var CUSTOMER_URI = 'customer';
var GROUP_URI = 'group';
var ROLE_URI = 'role';
var USER_URI = 'user';
var ADD = 'add';
var GET_ALL = 'getAll';
var UPDATE = 'update';
var DELETE = 'delete';
var MENU_URI = 'menu';
var ADMIN_URI = 'admin';
var KEY_VALUE_URI = 'getKeyValue';
var GET_GROUP_MENU = 'getAllGroupMenu';
var API_DEFINITION_URI = 'apiDefinition';
var KEY_VALUE_ROLE_GROUP = 'getKeyValueGroupAndRole';
var KEY_VALUE_API_DEFINITION_TYPE = 'getType';
var KEY_VALUE_API_DEFINITION_METHOD = 'getMethod';
var ENDPOINT_URL = 'apiEndpoint';
var ROLE_ENDPOINT_URL = 'roleEndpoint';
var KEY_VALUE_API_SERVICE_PROVIDER = 'getApiProviderKeyValue';
var FILE_IMPORT_API = 'fileImportApi';
var GET_USER_INFO = 'getUserInfo';
var AUDIT_URI = 'audit';
var AUDIT_ADD = 'log';
var SEARCH_CRITERIA = 'findBySearchCriteria';
var API_URI = 'api';
var TEST_CASE_URI = API_URI + SEPARATER + 'test';
var TEST_CASE_VIEW_URI = 'testCase';
var DOWNLOAD_API = 'downloadApi';
var METRICS_URI = 'metrics';
var TIMESERIES_CHART_URI = 'apiTimeSeriesChart';
var GET_TIMESERIES_CHART_URI = 'getTimeSeriesChart';
var GET_DONUTCHART_URI = 'getDonutChart';
var API_PRODUCT_URI = 'apiProduct';
var API_GROUP_URI = 'apiGroup';
var MFA_URI = 'mfa';

const criteria = {
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    GREATER_THAN_EQUAL: 'GREATER_THAN_EQUAL',
    LESS_THAN_EQUAL: 'LESS_THAN_EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    EQUAL: 'EQUAL',
    MATCH: 'MATCH',
    MATCH_START: 'MATCH_START',
    MATCH_END: 'MATCH_END',
    IN: 'IN',
    NOT_IN: 'NOT_IN'
}

const data = {
    SESSION_TOKE: 'SESSION_TOKE',
    SESSION_USER: 'SESSION_USER',
    IS_LOGIN: 'IS_LOGIN',
    LAST_LOGIN: 'LAST_LOGIN',
    USER_ROLE: 'USER_ROLE',
    MENU_LIST: 'MENU_LIST',
    RESPONSE_CODE: 'responseCode',
    SUCCESS: '00',
    DELETE_EVENT: 'DELETE_EVENT',
    ADD_TITLE: 'Add',
    UPDATE_TITLE: 'Update',
    DELETE_TITLE: 'Delete',
    PREVIOUS_ROUTE: 'PREVIOUS_ROUTE',
    USER_ID: 'USER_ID',
    ROLE_NAME: 'ROLE_NAME',
    USER_ACTIVITY: 'User',
    GROUP_ACTIVITY: 'Group',
    CUSTOMER_ACTIVITY: 'Customer',
    API_ENDPOINT_ACTIVITY: 'API Endpoint',
    API_DEFINITION_ACTIVITY: 'API Definition',
    ROLE_ACTIVITY: 'Role',
    CALL_TIME_COLUMN: 'callTime',
    SERVER_URL: 'SERVER_URL',
    MFA_ACTIVITY: 'MFA_User',
};

const url = {

    SEPARATER: SEPARATER,

    /**
     *  Customer URL
     */
    CUSTOMER_URI: CUSTOMER_URI,
    CUSTOMER_ADD: CUSTOMER_URI + SEPARATER + ADD,
    CUSTOMER_ALL: CUSTOMER_URI + SEPARATER + GET_ALL,
    CUSTOMER_GET: CUSTOMER_URI + SEPARATER,
    CUSTOMER_UPDATE: CUSTOMER_URI + SEPARATER + UPDATE,
    CUSTOMER_DELETE: CUSTOMER_URI + SEPARATER + DELETE + SEPARATER,
    CUSTOMER_KEY_VALUE: CUSTOMER_URI + SEPARATER + KEY_VALUE_URI,

    /**
     *  Group URL
     */
    GROUP_URI: GROUP_URI,
    GROUP_ADD: GROUP_URI + SEPARATER + ADD,
    GROUP_ALL: GROUP_URI + SEPARATER + GET_ALL,
    GROUP_GET: GROUP_URI + SEPARATER,
    GROUP_UPDATE: GROUP_URI + SEPARATER + UPDATE,
    GROUP_DELETE: GROUP_URI + SEPARATER + DELETE + SEPARATER,
    GROUP_KEY_VALUE: GROUP_URI + SEPARATER + KEY_VALUE_URI,
    GROUP_ROLE_KEY_VALUE: GROUP_URI + SEPARATER + KEY_VALUE_ROLE_GROUP,

    /**
     *  Role URL
     */
    ROLE_URI: ROLE_URI,
    ROLE_ADD: ROLE_URI + SEPARATER + ADD,
    ROLE_ALL: ROLE_URI + SEPARATER + GET_ALL,
    ROLE_GET: ROLE_URI + SEPARATER,
    ROLE_UPDATE: ROLE_URI + SEPARATER + UPDATE,
    ROLE_DELETE: ROLE_URI + SEPARATER + DELETE + SEPARATER,

    /**
     *  User URL
     */
    USER_URI: USER_URI,
    USER_ADD: USER_URI + SEPARATER + ADD,
    USER_ALL: USER_URI + SEPARATER + GET_ALL,
    USER_GET: USER_URI + SEPARATER,
    USER_UPDATE: USER_URI + SEPARATER + UPDATE,
    USER_DELETE: USER_URI + SEPARATER + DELETE + SEPARATER,

    /**
     *  API URL
     */
    API_URL: API_DEFINITION_URI,
    API_DEFINITION_KEY_VALUE: API_DEFINITION_URI + SEPARATER + KEY_VALUE_URI + SEPARATER,
    API_DEFINITION_TYPE_KEY_VALUE: API_DEFINITION_URI + SEPARATER + KEY_VALUE_API_DEFINITION_TYPE,
    API_DEFINITION_METHOD_KEY_VALUE: API_DEFINITION_URI + SEPARATER + KEY_VALUE_API_DEFINITION_METHOD,
    API_DEFINITION_SERVICE_PROVIDER_KEY_VALUE: API_DEFINITION_URI + SEPARATER + KEY_VALUE_API_SERVICE_PROVIDER,
    API_ALL: API_DEFINITION_URI + SEPARATER + GET_ALL,
    API_UPDATE: API_DEFINITION_URI + SEPARATER + UPDATE,
    API_DELETE: API_DEFINITION_URI + SEPARATER + DELETE + SEPARATER,
    API_GET: API_DEFINITION_URI + SEPARATER,
    API_DEFINITION_FILE_IMPORT_URI: API_DEFINITION_URI + SEPARATER + FILE_IMPORT_API,

    /**
     *  Endpoint URL
     */
    ENDPOINT_URL: ENDPOINT_URL,
    ENDPOINT_ADD: ENDPOINT_URL + SEPARATER + ADD,
    ENDPOINT_UPDATE: ENDPOINT_URL + SEPARATER + UPDATE,
    ENDPOINT_DELETE: ENDPOINT_URL + SEPARATER + DELETE + SEPARATER,
    ENDPOINT_ALL: ENDPOINT_URL + SEPARATER + GET_ALL,
    ENDPOINT_GET: ENDPOINT_URL + SEPARATER,

    /**
     * Role Endpoint URL
     */
    ROLE_ENDPOINT_URL: ROLE_ENDPOINT_URL,
    ROLE_ENDPOINT_ADD: ROLE_ENDPOINT_URL + SEPARATER + ADD,
    ROLE_ENDPOINT_UPDATE: ROLE_ENDPOINT_URL + SEPARATER + UPDATE,
    ROLE_ENDPOINT_DELETE: ROLE_ENDPOINT_URL + SEPARATER + DELETE + SEPARATER,
    ROLE_ENDPOINT_ALL: ROLE_ENDPOINT_URL + SEPARATER + GET_ALL,
    ROLE_ENDPOINT_GET: ROLE_ENDPOINT_URL + SEPARATER,

    /**
     *  Menu URL
     */
    HOME_URI: SEPARATER + 'home',
    MENU_ALL: MENU_URI + SEPARATER + GET_ALL,
    MENU_URI: MENU_URI,
    GROUP_MENU_ALL: MENU_URI + SEPARATER + GET_GROUP_MENU,
    LOGIN_URL: ADMIN_URI + SEPARATER + 'login',
    GET_USER_INFO_URI: ADMIN_URI + SEPARATER + GET_USER_INFO + SEPARATER,

    /**
     * User Audit URI
     */
    AUDIT_URI: AUDIT_URI + SEPARATER + AUDIT_ADD,
    AUDIT_GET_ALL_USER_URI: AUDIT_URI + SEPARATER + USER_URI + SEPARATER + GET_ALL,
    AUDIT_SEARCH_USER_CRITERIA: AUDIT_URI + SEPARATER + USER_URI + SEPARATER + SEARCH_CRITERIA,

    /**
     * Api Audit URI
     */
    API_AUDIT_GET_API_REQUEST_URI: AUDIT_URI + SEPARATER + API_URI + SEPARATER + GET_ALL,
    AUDIT_SEARCH_API_REQUEST_CRITERIA: AUDIT_URI + SEPARATER + API_URI + SEPARATER + SEARCH_CRITERIA,
    AUDIT_DOWNLOAD_API_REQUEST_CRITERIA: AUDIT_URI + SEPARATER + API_URI + SEPARATER + DOWNLOAD_API,

    /**
     * Test Case URL
     */
    TEST_CASE_URI: TEST_CASE_URI,
    TEST_CASE_URI_ADD: TEST_CASE_URI + SEPARATER + ADD,
    TEST_CASE_URI_GET_ALL: TEST_CASE_URI + SEPARATER + GET_ALL,
    TEST_CASE_URI_UPDATE: TEST_CASE_URI + SEPARATER + UPDATE,
    TEST_CASE_VIEW_URI: TEST_CASE_VIEW_URI,
    TEST_CASE_URI_DELETE: TEST_CASE_URI + SEPARATER + DELETE + SEPARATER,

    /**
     * METRICS URL
     */
    METRICS_URI: METRICS_URI,
    METRICS_URI_TIMESERIES_CHART_URI: METRICS_URI + SEPARATER + TIMESERIES_CHART_URI,
    GET_TIMESERIES_CHART_URI: METRICS_URI + SEPARATER + GET_TIMESERIES_CHART_URI,
    GET_DONUTCHART_URI: METRICS_URI + SEPARATER + GET_DONUTCHART_URI,

    /**
     * API Product URL
     */
    PRODUCT_KEYVALUE_URI: API_PRODUCT_URI + SEPARATER + KEY_VALUE_URI,

    /**
     * API Group URL
     */
    API_GROUP_KEYVALUE_URI: API_GROUP_URI + SEPARATER + KEY_VALUE_URI,

    /**
     * MFA USER URL
     */
    MFA_USER_ADD_URL: MFA_URI + SEPARATER + ADD,
    MFA_USER_GET_ALL_URL: MFA_URI + SEPARATER + GET_ALL,
    MFA_USER_GET_URL: MFA_URI + SEPARATER,
    MFA_USER_DELETE_URL: MFA_URI + SEPARATER + DELETE + SEPARATER,
    MFA_USER_UPDATE_URL: MFA_URI + SEPARATER + UPDATE,
}

const jsonPath = {
    REQUEST_BODY: "$.paths..requestBody.content.*",
    RESPONSE_BODY: "$.paths..responses..*..*..*",
    MEDIA_TYPE: "$..paths..requestBody.content.*~",
    PATH: "$..paths.*~",
    METHOD: "$..paths.*.*~",
    PARAMETERS: '$.paths..parameters.*'
}

const statusCodes = [100, 101, 102, 200, 201, 202, 203, 204, 205, 206, 207,
    300, 301, 302, 303, 304, 305, 307, 308, 400, 401, 402, 403, 404, 405, 406,
    407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 419, 420, 422,
    423, 424, 428, 429, 431, 451, 500, 501, 502, 503, 504, 505, 507, 511
];

const BG_COLOR = ['navy-bg', 'blue-bg', 'lazur-bg', 'yellow-bg', 'red-bg'];

const timeout = 600;

export default {
    data,
    url,
    SEPARATER,
    criteria,
    jsonPath,
    statusCodes,
    timeout
}